import React, {Component} from 'react'
import PropTypes from "prop-types";
import "../../../sass/SmarthomeAppPromoPC.css"
import pcHorizontal1 from "../../../images/index_popup/1-desktop-popup-image-promo-app.png"
import pcHorizontal2 from "../../../images/index_popup/3-desktop-popup-image-promo-app.png"
import tabletHorizontal1 from "../../../images/index_popup/1-tablet-popup-image-promo-app.png"
import tabletHorizontal2 from "../../../images/index_popup/3-tablet-popup-image-promo-app.png"

/**
 * Браузерная версия Промо
 */
export default class PopupBrowser extends Component {
    render() {
        const images = {
            "pcImage1": pcHorizontal1,
            "pcImage2": pcHorizontal2,
            "tabletImage1": tabletHorizontal1,
            "tabletImage2": tabletHorizontal2
        };

        const pcImage = images['pcImage' + this.props.popupImageIndex];
        const tabletImage = images['tabletImage' + this.props.popupImageIndex];

        return (
            <div>
                <div className="popup-pc">
                    <input type="button" className="popup-pc__btn-close" onClick={this.props.closeCallback}/>
                    <div className="popup-pc-blur"></div>
                    <div className="popup-pc-body">
                        <picture>
                            <source srcSet={pcImage} type="image/png" media="(min-width: 1300px)"/>
                            <source srcSet={tabletImage} type="image/png" media="(max-width: 1299px)"/>
                            <img src={pcImage} alt="Всплывающее окно"/>
                        </picture>
                        {
                            this.props.buttons && this.props.buttons.map((button, key) => {
                                return React.cloneElement(button, {key})
                            })
                        }
                    </div>
                </div>
            </div>

        )
    }
}

PopupBrowser.propTypes = {
    popupImageIndex: PropTypes.any.isRequired,
    buttons: PropTypes.array,
    closeCallback: PropTypes.func
}

import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import AttentionPopUpWrapper from '../Header/PopUps/AttentionPopUpWrapper'
import VideoPlayer from '../VideoPlayer'
import {CITYLINK} from "../../helpers/constants/HeaderConstants";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Promo from '../Promo';
import {CAMERA_TYPE_NEARBY_CAMERAS, CAMERA_TYPE_PUBLIC, CAMERA_TYPE_USER_CAMERAS} from "../UserCameras/UserCamera";
import {NEARBY_CAMERAS_PATH, USER_CAMERAS_PATH} from "../../routes";

/**
 * Контейнер мобильной версии Архива
 */
export default class MobileContainer extends Component {
    componentDidMount(){
        document.documentElement.setAttribute('lang', "ru");
    }
    render() {
        let cameraType = this.props.cameraType
        const playlistUrl = this.props.playlistUrl
        let basePath;

        switch (cameraType) {
            case CAMERA_TYPE_PUBLIC:
                basePath = '/pub';
                break;
            case CAMERA_TYPE_USER_CAMERAS:
                basePath = USER_CAMERAS_PATH
                break;
            case CAMERA_TYPE_NEARBY_CAMERAS:
                basePath = NEARBY_CAMERAS_PATH
                break
        }

        let isPublic = cameraType === CAMERA_TYPE_PUBLIC

        let camera = this.props.camera

        return (
            <div className="mobile-wrapper">
                <div className="content-archive_tool">
                    <div className="input-form__wrapper">
                        <div className="calendar">
                            <label>Выберите дату:</label>
                            {this.props.calendar}
                        </div>
                        <div className="user-buttons">
                            <label>Выберите время:</label>
                            <div className="begin-wrapper">
                                <input
                                    type="time"
                                    className="begin"
                                    defaultValue={this.props.getTimeFromVal(this.props.minValue)}
                                    onChange={this.props.changedMin}
                                />
                            </div>
                            <div className="end-wrapper">
                                <input
                                    type="time"
                                    className="end"
                                    onChange={this.props.changedMax}
                                    defaultValue={this.props.getTimeFromVal(this.props.maxValue)}
                                />
                            </div>
                            <button
                                className="search"
                                onClick={() => this.props.handleSearch(camera, true)}>
                                Искать
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mobile-video-player archive" key={this.props._search}>
                    <AttentionPopUpWrapper
                        textFirst={this.props.attentionTextFirst}
                        textSecond={this.props.attentionTextSecond}
                        showPopUp={this.props.showAttentionPopUp}
                        handleClosePopUp={this.props.handleClosePopUp}
                    />
                    <VideoPlayer
                        key={playlistUrl}
                        width={'350'}
                        height={'197'}
                        source={playlistUrl}
                        audio={camera.audio}
                        poster={camera.img}
                        type={'application/x-mpegURL'}
                        isArchive={true}
                        cameraId={camera.id}
                        cameraType={this.props.cameraType.substring(0, this.props.cameraType.length - 8)} //отрезаем "_cameras"
                    />
                    <div className="video-player-info">
                        <span className="video-player_name">{camera.name}</span>
                        <Link
                            className="video-player_link"
                            to={`${basePath}/${camera.id}`}>
                            Онлайн
                        </Link>
                        {
                            isPublic && camera.incident !== 0 &&
                            <Link className="video-player_link" to={`/public/videos/${camera.id}`}>
                                Происшествия
                            </Link>
                        }
                        <span>
                            <a className="video-player_link" href='# '
                               onClick={() => this.props.handleGetArchiveVideo(camera)}>Скачать</a>
                        </span>
                    </div>
                    {
                        CITYLINK && <Promo type='mobile'/>
                    }
                </div>
            </div>
        )
    }
}

import React from 'react';
import {Route, Switch, Redirect} from 'react-router';

import About from './components/About';
import Faq from './components/Faq';
import CameraPublicWithPromo from './components/CameraPublicWithPromo';
import Archive from './components/Archive';
import ArchiveScreen from './components/ArchiveScreen';
import NearbyCameras from './components/NearbyCameras';
import PublicMap from './components/PublicMap';
import PublicSensors from './components/PublicSensors';
import PublicList from './components/PublicList';
import TrubaVideo from './components/TrubaVideo';
import TrubaList from './components/TrubaList';
import Pdd from './components/Pdd';
import Sosed from './components/Sosed';
import SosedVideo from './components/SosedVideo';
import SosedMyVideo from './components/SosedMyVideo';
import ShareVideo from './components/ShareVideo';
import Rules from './components/Rules';
import Privacy from './components/Privacy';
import NotFound from './components/NotFound';
import Header from './components/Header';
import {RELANT, MOBILE} from './helpers/constants/HeaderConstants';
import Footer from './components/Footer';
import QuickAccessForUr from './components/QuickAccessForUr';
import Domofon from "./components/domofon";
import RelantPlug from './components/relantPlug';
import SmsLogin from "./components/SmsCodeLogin/SmsLogin";
import UserCameras from "./components/UserCameras/UserCameras";
import UserCamera, {
    CAMERA_TYPE_NEARBY_CAMERAS,
    CAMERA_TYPE_PUBLIC,
    CAMERA_TYPE_USER_CAMERAS
} from "./components/UserCameras/UserCamera";
import FramePage from "./components/FramesPage";
import MenuProvider from "./MenuProvider";
import {Router} from "react-router-dom";
import EventDispatcher from "./EventDispatcher";
import BackgroundTheme from "./components/BackgroundTheme";
import {mdApi} from "./index";
import Popup, {STRATEGY_RANDOM} from "./components/Promo/Citylink/Popup";
import ArchiveError from "./components/Archive/ArchiveError"
import BannerNotification from "./components/BannerNotification";
import SpecialAccessMap from "./components/SpecialAccessMap";
import SpecialAccessList from "./components/SpecialAccessList";

const APP_URL_PREFIX = process.env.REACT_APP_APP_URL_PREFIX;

export const history = require('history').createBrowserHistory({basename: APP_URL_PREFIX})
const isNotDomofonLanding = history.location.pathname !== '/domofon' && history.location.pathname !== '/domofon/';
const isNot404Page = history.location.pathname !== '/404' && history.location.pathname !== '/404/';
const isNotArchiveError = history.location.pathname !== '/archive-error' && history.location.pathname !== '/archive-error/';

export const ABOUT_PATH = "/about"
export const FAQ_PATH = "/faq"
export const NOT_FOUND_PATH = `/404`
export const PUBLIC_MAP_PATH = ``
export const PUBLIC_LIST_PATH = `/publist`
export const USER_CAMERAS_PATH = `/cameras`
export const NEARBY_CAMERAS_PATH = `/nearby-cameras`
export const FRAME_PATH = `/frame`

export const ARCHIVE_ERROR = `/archive-error`

const ctx = {
    addCallback: (name, callback) => {
        ctx[name] = callback
    }
}
export const AppContext = React.createContext(ctx)

export const rolesToPermissions = (roles) => {
    let showAdminUrl = roles.includes('ROLE_ADMIN_PAGE_ACCESS')

    return {
        showAdminUrl
    }
}

const Root = () => {
    return (
        <Router history={history}>

            <div className="App">
                <AppContext.Provider value={ctx}>
                    {
                        RELANT &&
                        <RelantPlug/>
                    }
                    {
                        !RELANT &&
                        <>
                            <div className="content">
                                {isNotDomofonLanding && <Header/>}
                                {
                                    //https://redmine.citylink.pro/issues/27417, https://redmine.citylink.pro/issues/32029
                                    // 2 баннера, выдаем Клиенту их рандомно. При этом, если один из баннеров Клиент уже просмотрел, то через сутки выдаем другой баннер.
                                    // После показа 2-х баннеров, опять выдаем баннеры через неделю. Затем перестаем показывать.
                                    isNotDomofonLanding && isNot404Page && isNotArchiveError
                                    &&

                                    // <BannerNotification/> // заглушка тех работ, на всякий оставил.
                                    <Popup stages={2}
                                           stageInterval={7 * 24 * 60} // week
                                           strategy={STRATEGY_RANDOM}
                                           stagePopupInterval={24 * 60} //day
                                    />
                                }
                                <Route
                                    path="*"
                                    render={({match}) => {
                                        const url = match.params[0]
                                        if (url.endsWith('/') && url !== '/') {
                                            const urlCanonical = url.slice(0, -1)

                                            return <Redirect to={urlCanonical}/>;
                                        }

                                        return null;
                                    }}
                                />
                                <Switch>

                                    <Route exact path="/domofon" component={Domofon}/>
                                    {
                                        MOBILE && <Redirect exact from="/" to={PUBLIC_LIST_PATH}/>
                                    }

                                    <Route exact path={`${PUBLIC_LIST_PATH}/:city?`} component={PublicList}/>

                                    <Route exact path="/pub/:cameraId(\d+)" component={CameraPublicWithPromo}/>
                                    <Route exact path="/pub/:cameraId(\d+)/noredirect"
                                           component={CameraPublicWithPromo}/>

                                    <Route exact path="/pub/:cameraId(\d+)/archive/:search?"
                                           render={props => <Archive cameraType={CAMERA_TYPE_PUBLIC} {...props} />}/>
                                    <Route exact path="/pub/:cameraId(\d+)/screen/:search?"
                                           render={props => <ArchiveScreen
                                               cameraType={CAMERA_TYPE_PUBLIC} {...props} />}/>

                                    <Route exact path={USER_CAMERAS_PATH} component={UserCameras}/>
                                    <Route exact path={`${USER_CAMERAS_PATH}/:cameraId`}
                                           render={props => <UserCamera
                                               camerasType={CAMERA_TYPE_USER_CAMERAS} {...props} />}/>
                                    <Route exact path={`${USER_CAMERAS_PATH}/:cameraId/archive/:search?`}
                                           render={props => <Archive
                                               cameraType={CAMERA_TYPE_USER_CAMERAS} {...props} />}/>
                                    <Route exact path={`${USER_CAMERAS_PATH}/:cameraId/screen/:search?`}
                                           render={props => <ArchiveScreen
                                               cameraType={CAMERA_TYPE_USER_CAMERAS} {...props} />}/>

                                    <Route exact path={NEARBY_CAMERAS_PATH} component={NearbyCameras}/>
                                    <Route exact path={`${NEARBY_CAMERAS_PATH}/:cameraId`}
                                           render={props => <UserCamera
                                               camerasType={CAMERA_TYPE_NEARBY_CAMERAS} {...props} />}/>
                                    <Route exact path={`${NEARBY_CAMERAS_PATH}/:cameraId/archive/:search?`}
                                           render={props => <Archive
                                               cameraType={CAMERA_TYPE_NEARBY_CAMERAS} {...props} />}/>
                                    <Route exact path={`${NEARBY_CAMERAS_PATH}/:cameraId/screen/:search?`}
                                           render={props => <ArchiveScreen
                                               cameraType={CAMERA_TYPE_NEARBY_CAMERAS} {...props} />}/>

                                    {/*<Route exact path="/camera/:cameraId(\d+)" component={Camera}/>*/}
                                    {/*<Route exact path="/camera/:cameraId(\d+)/archive/:search?" component={Archive}/>*/}
                                    {/*<Route exact path="/camera/:cameraId(\d+)/screen/:search?" component={ArchiveScreen}/>*/}

                                    <Route exact path="/videos/:category(\w+)?" component={TrubaList}/>
                                    <Route exact path="/video/:trubaId(\d+)" component={TrubaVideo}/>
                                    <Route exact path="/pdd" component={Pdd}/>
                                    <Route exact path="/pdd/:trubaId(\d+)" component={TrubaVideo}/>

                                    <Route exact path="/public/videos/code" render={() => {
                                        return <Redirect to="/"/>;
                                    }}/>
                                    <Route exact path="/public/videos/:cameraId(\d+)" component={TrubaList}/>

                                    <Route exact path="/sensors/:city(\w+)?" component={PublicSensors}/>

                                    <Route path={ABOUT_PATH} component={About}/>

                                    <Route path={FAQ_PATH} component={Faq}/>
                                    <Route path="/sosedi" component={Sosed}/>
                                    <Route path="/sosed/:sosedId(\d+)" component={SosedVideo}/>
                                    <Route path="/my/:sosedId(\d+)" component={SosedMyVideo}/>
                                    <Route path="/code/:search?" component={SmsLogin}/>

                                    <Route exact path={`/speclist/:city?`} component={SpecialAccessList}/>
                                    {
                                        // на мобильном устройстве необходимо отрендерить компонент списка
                                        MOBILE &&
                                        <Route exact path={`/spec/:city?`} component={SpecialAccessList}/>
                                    }
                                    {
                                        !MOBILE &&
                                        <Route exact path={`/spec/:city?`}
                                               render={props => <SpecialAccessMap key={props.location.key} {...props}/>}/>
                                    }

                                    <Route exact path="/share/:trubaId(\w+)" component={ShareVideo}/>
                                    <Route exact path="/rules" component={Rules}/>
                                    <Route exact path="/privacy" component={Privacy}/>
                                    <Route exact path="/quick/:codeUrl" component={QuickAccessForUr}/>

                                    <Route exact path={`${FRAME_PATH}/:frameIdentity`} component={FramePage}/>

                                    <Route exact path={ARCHIVE_ERROR} component={ArchiveError}/>

                                    <Route exact path={NOT_FOUND_PATH} component={NotFound}/>

                                    {
                                        // на мобильном устройстве необходимо отрендерить компонент списка
                                        MOBILE &&
                                        <Route exact path={`${PUBLIC_MAP_PATH}/:city?`} component={PublicList}/>
                                    }
                                    {
                                        !MOBILE &&
                                        <Route exact path={`${PUBLIC_MAP_PATH}/:city?`}
                                               render={props => <PublicMap key={props.location.key} {...props}/>}/>
                                    }

                                    <Route exact path="*" component={NotFound}/>
                                </Switch>
                            </div>
                            {isNotDomofonLanding  && <Footer/>}
                        </>
                    }
                    <MenuProvider/>
                    <EventDispatcher/>
                    <BackgroundTheme/>
                </AppContext.Provider>
            </div>
        </Router>)
}

export default Root

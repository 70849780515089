import React, {Component} from 'react'
import {Link, NavLink} from "react-router-dom";
import {code} from "../../helpers/constants/HeaderConstants";
import BrowserCitylink from "./Citylink/BrowserCitylink";
import {ABOUT_PATH, AppContext, rolesToPermissions, USER_CAMERAS_PATH} from "../../routes";
import {mdApi} from "../../index";

export const PASSPORT_AUTH_URL = "https://auth.citylink.pro/oauth/authorize?client_id=1&redirect_uri=https://moidom.citylink.pro/web/api/login_by_passport&response_type=code&scope="
export const PASSPORT_LOGOUT_URL = "https://auth.citylink.pro/logout?callback=https://moidom.citylink.pro"

export class BrowserContainer extends Component {
    constructor(props) {
        super(props);

        BrowserContainer.contextType = AppContext
    }

    redirectToPassportAuth = () => {
        window.location.href = PASSPORT_AUTH_URL
    }

    render() {
        const isAuthorised = this.props.isAuthorised
        const isEnter = this.props.isEnter
        const handleEnterList = this.props.handleEnterList
        const userLevel = this.props.userLevel
        const userName = this.props.userName
        const handleExitBtn = this.props.handleExitBtn

        const availableLabels = [
            "Администрирование",
            "🔥👉Администрирование👈🔥",
            "Добавление камер",
            "Настройки МойДом",
            "Панель управления",
            "Админка",
            "Доступ для сотрудников"
        ]

        const adminLabel = availableLabels[Math.floor(Math.random() * availableLabels.length)]

        let {showAdminUrl} = rolesToPermissions(mdApi.userRoles)

        return (
            <div className="App-wrapper">
                <header className="App-header">
                    <div className="App-header_top">
                        <ul className="links links-left">
                            <li className="list__item">
                                <NavLink to={ABOUT_PATH}>О проекте</NavLink>
                            </li>
                        </ul>
                        <div className="Logo-center">
                            <Link to="/" className="logo-link"/>
                        </div>
                        <ul className="links links-right">
                            {
                                isAuthorised &&
                                <>
                                    <li className="list__item name" style={{display: 'flex', flexDirection: 'column'}}>
                                        <a href="# ">{userName}</a>
                                        {
                                            showAdminUrl &&
                                            <a href={process.env.REACT_APP_ADMIN_URL} className={"blinking_label"}
                                               style={{textAlign: 'right'}}>{adminLabel}</a>
                                        }
                                    </li>
                                    <li className="list__item entrance">
                                        <a href="# " onClick={() => handleExitBtn()}>
                                            (Выход)
                                        </a>
                                    </li>
                                </>
                            }
                            {
                                !isAuthorised &&
                                <li className="list__item entrance">
                                    <a className="auth" href="# " onClick={() => handleEnterList()}>Вход</a>
                                    {
                                        isEnter &&
                                        <div className="enter-list">
                                            <a className="enter-list_item" href="# "
                                               onClick={this.redirectToPassportAuth}>По логину</a>
                                            <Link className="enter-list_item"
                                                  to='/code#'>По коду доступа</Link>
                                        </div>
                                    }
                                </li>
                            }

                            <li className="list__item">
                                <div id="cl_widget_links"/>
                            </li>
                        </ul>
                    </div>
                    <div className="App-header_bottom">
                        <BrowserCitylink
                            isAuthorised={isAuthorised}
                            userLevel={userLevel}
                            checkActivePage={this.props.checkActivePage}
                            showProjectInAction={this.props.showProjectInAction}
                        />
                    </div>
                </header>
            </div>
        )
    }
}

export default BrowserContainer;


